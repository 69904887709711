import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import LockIcon from '@mui/icons-material/Lock';
import { TextField } from '@mui/material';
import bcrypt from 'bcryptjs';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { firestore, storage } from '../firebaseConfig';
import Navbar from './Navbar';
import './TextEditor.css';

export default function TextEditor() {
  const { domainName } = useParams();
  const [text, setText] = useState('');
  const [files, setFiles] = useState([]);
  const [showFileSection, setShowFileSection] = useState(true);
  const [isProtected, setIsProtected] = useState(false); // New state for password protection
  const [inputPassword, setInputPassword] = useState(''); // New state for password input
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [lockPassword, setLockPassword] = useState('');
  const [lockPasswordInput, setLockPasswordInput] = useState('');
  const [sessionEditable, setSessionEditable] = useState(false);
  const [isLockModalOpen, setIsLockModalOpen] = useState(false);
  const [isUnlockModalOpen, setIsUnlockModalOpen] = useState(false);
  const [isUnlockPrompt, setIsUnlockPrompt] = useState(false);
  const fileInputRef = useRef(); // Ref to trigger file dialog
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(firestore, 'documents', domainName);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setIsProtected(data.passwordSet); // Check if password is set
          setIsLocked(data.isLocked || false);
          setLockPassword(data.lockPassword || '');
          setFiles(data.files || []);
          if (!data.passwordSet) {
            setText(data.text || ''); // Only set text if no password
          } else {
            console.log('No such document!');
          }
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchData();
  }, [domainName, navigate]);

  const openLockModal = () => setIsLockModalOpen(true);
  const closeLockModal = () => setIsLockModalOpen(false);
  const openUnlockModal = () => setIsUnlockModalOpen(true);
  const closeUnlockModal = () => setIsUnlockModalOpen(false);
  const openUnlockPrompt = () => setIsUnlockPrompt(true);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handlePasswordSubmit();
    }
  };

  const handlePasswordSubmit = async () => {
    try {
      const docRef = doc(firestore, 'documents', domainName);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();

        // Verify password using bcrypt
        const isPasswordCorrect = await bcrypt.compare(inputPassword, data.password);
        if (isPasswordCorrect) {
          setText(data.text || '');
          setIsAuthenticated(true);
          toast.success('Password verified!');
        } else {
          toast.error('Incorrect password.');
        }
      }
    } catch (error) {
      console.error('Error verifying password:', error);
      toast.error('Error verifying password.');
    }
  };

  const handleSave = async () => {
    try {
      if (isLocked && !sessionEditable) {
        toast.error('This domain is locked. Unlock the domain to save changes.');
        return;
      }
      const docRef = doc(firestore, 'documents', domainName);
      const docSnap = await getDoc(docRef);
      const existingData = docSnap.exists() ? docSnap.data() : {};

      const newData = {
        ...existingData,
        text: text,
        files: files,
      };

      await setDoc(docRef, newData);
      toast.success('Document successfully saved!');
    } catch (error) {
      console.error('Error writing document:', error);
      toast.error('Error saving document.');
    }
  };

  const handleLock = async () => {
    if (!isLocked) {
      if (lockPasswordInput) {
        const hashedPassword = bcrypt.hashSync(lockPasswordInput, 10);
        setIsLocked(true);
        setSessionEditable(true);
        const docRef = doc(firestore, 'documents', domainName);
        const docSnap = await getDoc(docRef);
        const existingData = docSnap.exists() ? docSnap.data() : {};
        const newData = {
          ...existingData,
          text,
          files,
          isLocked: true,
          lockPassword: hashedPassword,
        };
        await setDoc(docRef, newData);
        setLockPasswordInput('');
        setIsLockModalOpen(false);
        toast.success('Domain locked and saved successfully!');
      } else {
        toast.error('Password is required to lock the domain.');
      }
    } else {
      if (bcrypt.compareSync(lockPasswordInput, lockPassword)) {
        setSessionEditable(true);
        setIsUnlockModalOpen(false);
        toast.success('Domain unlocked for this session.');
      } else {
        toast.error('Incorrect password.');
      }
    }
  };

  const handleUnlock = async () => {
    setIsUnlockPrompt(false);
    setIsLocked(false);
    setLockPassword('');
    setSessionEditable(false);
    const docRef = doc(firestore, 'documents', domainName);
    const docSnap = await getDoc(docRef);
    const existingData = docSnap.exists() ? docSnap.data() : {};
    const newData = {
      ...existingData,
      text,
      files,
      isLocked: false,
      lockPassword: "",
    };
    await setDoc(docRef, newData);
    toast.success('Domain unlocked for public editing and saved.');
  };

  const handleFileChange = async (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const maxFiles = 5;
      const maxFileSizeMB = 200;
      const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;

      if (isLocked && !sessionEditable) {
        toast.error(`This domain is locked. Unlock the upload files`);
        return;
      }

      // Check if the file size exceeds the limit
      if (file.size > maxFileSizeBytes) {
        toast.error(`File size exceeds the ${maxFileSizeMB}MB limit. Please upload a smaller file.`);
        return;
      }

      // Check if the number of files exceeds the limit
      if (files.length >= maxFiles) {
        toast.error(`You can only upload up to ${maxFiles} files per domain.`);
        return;
      }

      setIsUploading(true);
      try {
        const fileRef = ref(storage, `files/${domainName}/${file.name}`);
        await uploadBytes(fileRef, file);
        const url = await getDownloadURL(fileRef);
        setFiles([...files, { name: file.name, url }]);
        toast.success('File uploaded successfully!');
      } catch (error) {
        console.error('Error uploading file:', error);
        toast.error('Error uploading file.');
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleText = (event) => {
    if (!isLocked || sessionEditable) {
      setText(event);
    }
    else {
      toast.error('This domain is locked. Unlock the domain to edit.');
    }
  }

  const handleDeleteFile = async (file) => {
    try {
      // Reference to the file in Firebase Storage
      const fileRef = ref(storage, `files/${domainName}/${file.name}`);

      // Delete the file from Firebase Storage
      await deleteObject(fileRef);

      // Update Firestore by removing the file from the files array
      const updatedFiles = files.filter((f) => f.name !== file.name);
      setFiles(updatedFiles); // Update the state immediately for UI responsiveness

      const docRef = doc(firestore, 'documents', domainName);
      const existingData = (await getDoc(docRef)).data();
      await setDoc(docRef, { ...existingData, files: updatedFiles });

      toast.success('File deleted successfully!');
    } catch (error) {
      console.error('Error deleting file:', error);
      toast.error('Error deleting file.');
    } finally {
      setFileToDelete(null); // Close modal
    }
  };

  const triggerFileUpload = () => {
    if (isLocked && !sessionEditable) {
      toast.error('This domain is locked. Unlock the domain to upload files.');
      return;
    }
    fileInputRef.current.click(); // Trigger the file dialog
  };

  const toggleFileSection = () => {
    setShowFileSection(!showFileSection); // Toggle file section visibility
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text); // Copy text to clipboard
    toast.success('Text copied to clipboard!');
  };



  return (
    <div className="editor-container">
      <Navbar />
      <ToastContainer />
      {!isAuthenticated && isProtected ? (
        <div className="password-protected-container">
          <div className="password-card">
            <h2 className="password-title">🔒 Secure Document</h2>
            <p className="password-description">
              This document is password-protected. Please enter your password to unlock access.
            </p>
            <div className="password-input-container">
              <input
                type="password"
                value={inputPassword}
                onChange={(e) => setInputPassword(e.target.value)}
                onKeyDown={handleKeyPress}
                placeholder="Enter Password"
                className="password-input-field"
              />
              <button onClick={handlePasswordSubmit} className="unlock-button">
                Unlock
              </button>
            </div>
            {/* <p className="password-hint">Hint: Make sure the password is case-sensitive.</p> */}
          </div>
        </div>
      ) : (
        <div className='entire-screen'>
          <div className='everything-except-save'>
            <div className="split-screen">
              <div className="text-section">
                <textarea
                  value={text}
                  placeholder="Enter text and hit save button"
                  onChange={(e) => handleText(e.target.value)}
                  className="text-area"
                />
                <div className="copy-button" onClick={copyToClipboard}>
                  <ContentCopyIcon />
                </div>
                {/* <button onClick={toggleFileSection} className="toggle-button">
                  {showFileSection ? (
                    <ArrowDropDownIcon fontSize="large" />
                  ) : (
                    <ArrowDropUpIcon fontSize="large" /> 
                  )}
                </button> */}

              </div>

              {showFileSection &&
                <div className="upload-section">
                  <div className="file-upload">
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                    {files.length > 0 && (
                      <div className="uploaded-files">
                        {files.map((file, index) => (
                          <div key={index} className="file-item">
                            <div className="file-icon">📄</div>
                            <div className="file-details">
                              <a href={file.url} target="_blank" rel="noopener noreferrer" className="file-name">
                                {file.name}
                              </a>
                              <div className="file-actions">
                                <button
                                  className="download-button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const link = document.createElement('a');
                                    link.href = file.url;
                                    link.download = file.name;
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                  }}
                                >
                                  Download
                                </button>
                                <button
                                  className="delete-button"
                                  onClick={() => {
                                    if (isLocked && !sessionEditable) {
                                      toast.error('This domain is locked. Unlock the domain to delete files.');
                                      return;
                                    }
                                    setFileToDelete(file)
                                  }} // Open modal
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <button onClick={triggerFileUpload} className="upload-button">
                      <FileUploadIcon />{isUploading ? "Uploading..." : "Upload File"}
                    </button>
                  </div>
                </div>
              }
            </div>
            <div className='bottom-btns'>
              <button onClick={handleSave} className="save-button">Save</button>
              <button onClick={isLocked ? (sessionEditable ? openUnlockPrompt : openUnlockModal) : openLockModal} className="lock-button">
                <LockIcon /> {isLocked ? (sessionEditable ? 'Unlock' : 'Edit') : 'Lock'}
              </button>
            </div>
          </div>
          {fileToDelete && (
            <div className="modal-overlay">
              <div className="modal-box">
                <p>Are you sure you want to delete <strong>{fileToDelete.name}</strong>? This action cannot be undone.</p>
                <div className="modal-actions">
                  <button onClick={() => handleDeleteFile(fileToDelete)} className="confirm-button">Yes, Delete</button>
                  <button onClick={() => setFileToDelete(null)} className="cancel-button">Cancel</button>
                </div>
              </div>
            </div>
          )}
          {isUnlockPrompt && (
            <div className="modal-overlay">
              <div className="modal-box">
                <p>Are you sure you want to make the domain editable to the public?</p>
                <div className="modal-actions">
                  <button onClick={() => handleUnlock()} className="confirm-button">Yes, Unlock</button>
                  <button onClick={() => setIsUnlockPrompt(false)} className="cancel-button">Cancel</button>
                </div>
              </div>
            </div>
          )}
          {isLockModalOpen && (
            <div className="modal-overlay">
              <div className="modal-box">
                <h4>Set Password for Lock</h4>
                <TextField
                  type="password"
                  label="Enter Password"
                  fullWidth
                  value={lockPasswordInput}
                  onChange={(e) => setLockPasswordInput(e.target.value)}
                />
                <div className="modal-actions">
                  <button onClick={handleLock} className="confirm-button">
                    Lock
                  </button>
                  <button onClick={closeLockModal} className="cancel-button">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
          {isUnlockModalOpen && (
            <div className="modal-overlay">
              <div className="modal-box">
                <h4>Enter Password to Unlock</h4>
                <TextField
                  type="password"
                  label="Enter Password"
                  fullWidth
                  value={lockPasswordInput}
                  onChange={(e) => setLockPasswordInput(e.target.value)}
                />
                <div className="modal-actions">
                  <button onClick={handleLock} className="confirm-button-green">
                    Unlock
                  </button>
                  <button onClick={closeUnlockModal} className="cancel-button">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

        </div>
      )}
    </div>
  );
}