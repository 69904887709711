import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from './LOGO.png';
import './Navbar.css';

const Navbar = () => {
  const location = useLocation();

  const isTextEditor = location.pathname !== '/' && location.pathname.includes('/');

  return (
    <div className="navbar">
      {!isTextEditor && (
        <div className="navbar-left">
          <img className="logo" src={logo} alt="Logo" />
          <div className="navbar-title"><span className='no'>No</span><span className='login'>Login</span></div>
        </div>
      )}

      <div className="navbar-right">
        {location.pathname === '/' && (
          <>
            <Link to="/" className="nav-button active">Home</Link>
            <Link to="/about" className="nav-button">About Us</Link>
            <Link to="/TermsAndConditions" className="nav-button">Terms & Conditions</Link>
          </>
        )}

        {isTextEditor && (
          <Link to="/" className="nav-button active">Home</Link>
        )}

        {location.pathname !== '/' && !isTextEditor && (
          <>
            <Link to="/" className="nav-button active">Home</Link>
            <Link to="/about" className="nav-button">About Us</Link>
            <Link to="/TermsAndConditions" className="nav-button">Terms & Conditions</Link>
          </>
        )}
      </div>
    </div>
  );
};

export default Navbar;
